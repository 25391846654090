<template>
  <div class="home">
    <div class="intro">
      <div class="container grid-md">
        <div class="columns">
          <div class="column col-7 col-md-12 col-sm-12">
            <div class="tabs">
              <div class="tabs__content">
                <div class="tabs__content-item">
                  <h1>{{ $t("home.intro.headline") }}</h1>
                  <div ref="introTextGroup" class="intro__text-group">
                    <h3>{{ $t("home.intro.text") }}</h3>
                    <router-link
                      class="button button--arrow-right"
                      to="/service/executive-search"
                      >{{
                        $t("home.intro.button_executive-search")
                      }}</router-link
                    >
                    <router-link
                      class="button button--arrow-right"
                      to="/service/board-services"
                      >{{
                        $t("home.intro.button_board-ceo-service")
                      }}</router-link
                    >
                  </div>
                  <!-- <LocaleChanger class="mobile" /> -->
                </div>
              </div>
            </div>
          </div>
          <div class="column col-5 col-md-12 col-sm-12 intro__image-group">
            <img
              src="../assets/images/home/nm_home_header_illustration-mobile.png"
              class="intro__image intro__image--mobile"
            />
            <img
              src="../assets/images/home/nm_home_header_illustration0.png"
              class="intro__image"
              v-scroll-context="{
                callback: scrollImage,
                params: { multiplier: -1.5 },
                throttle: 10,
              }"
            />
            <img
              ref="introImage1"
              src="../assets/images/home/nm_home_header_illustration1.png"
              class="intro__image"
              v-scroll-context="{
                callback: scrollImage,
                params: { multiplier: -1.5 },
                throttle: 10,
              }"
            />
          </div>
        </div>
      </div>
      <div class="container grid-md">
        <div class="columns">
          <div class="column col-12">
            <!-- <LocaleChanger class="desktop" /> -->
          </div>
        </div>
      </div>
    </div>
    <div ref="teasersGroup" class="teasers__group">
      <div class="teasers teasers--culture">
        <div class="container grid-md">
          <div class="columns">
            <div class="column col-9 col-md-9 col-sm-12">
              <h4 class="teaser__subline" ref="culture">
                <span>{{ $t("home.culture.subline") }}</span
                ><span class="teaser__subline-separator">&nbsp;－&nbsp;</span
                ><span class="teaser__subline--inactive">{{
                  $t("home.personalities.subline")
                }}</span>
              </h4>
              <div class="teaser" v-html="$t('home.culture.text')" />
            </div>
          </div>
        </div>
      </div>
      <div
        class="teasers teasers--personalities"
        v-scroll-context="{
          callback: scrollCallback,
          params: { multiplier: 0.05 },
          throttle: 30,
        }"
      >
        <div class="container grid-md" ref="personalitiesText">
          <div class="columns">
            <div class="column col-9 col-md-9 col-sm-12">
              <h4 class="teaser__subline" ref="personalities">
                <span class="teaser__subline--inactive">{{
                  $t("home.culture.subline")
                }}</span
                ><span class="teaser__subline-separator">&nbsp;－&nbsp;</span
                ><span>{{ $t("home.personalities.subline") }}</span>
              </h4>
              <div class="teaser" v-html="$t('home.personalities.text')" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="teasers teasers--team">
      <div class="container grid-md">
        <div class="columns">
          <div class="column col-7 col-sm-12">
            <h2 v-html="$t('home.team.headline')"></h2>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <TeamGallery v-bind:passedActiveMember="0" />
          </div>
          <div class="column col-5 col-sm-12">
            <router-link class="button button--arrow-right" to="/about/team">{{
              $t("home.team.link")
            }}</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="teasers teasers--network">
      <div class="container grid-md">
        <div class="columns">
          <div class="column col-7 col-sm-12">
            <h3>{{ $t("home.network.headline") }}</h3>
            <router-link
              class="button button--arrow-right"
              to="/about/network"
              >{{ $t("home.network.link") }}</router-link
            >
          </div>
          <div class="column col-5 col-sm-12 txt--right">
            <img src="../assets/images/home/nm_home_teaser1_illustration.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="teasers teasers--candidate">
      <div class="container grid-md">
        <div class="columns">
          <div class="column col-6 col-sm-12">
            <h3>{{ $t("home.candidate.headline") }}</h3>
            <router-link class="button button--arrow-right" to="/candidate">{{
              $t("home.candidate.link")
            }}</router-link>
          </div>
          <div class="column col-6 col-sm-12 txt--right">
            <img src="../assets/images/home/nm_home_teaser2_illustration.png" />
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="teasers teasers--futurehunt">
      <div class="container grid-md">
        <div class="columns">
          <div class="column col-6 col-sm-12">
            <h3>{{ $t("home.futurehunt.headline") }}</h3>
            <router-link class="button button--arrow-right" to="/futurehunt">{{
              $t("home.futurehunt.link")
            }}</router-link>
          </div>
          <div class="column col-6 col-sm-12 txt--right">
            <img src="../assets/images/home/nm_home_teaser3_illustration.png" />
          </div>
        </div>
      </div>
    </div> -->
    <div class="teasers teasers--logos">
      <div class="container grid-md">
        <div class="columns">
          <div class="column col-12 col-sm-12">
            <div class="logo__container">
              <img src="../assets/images/home/logos/andritz.svg" />
              <img src="../assets/images/home/logos/arla.svg" />
              <img src="../assets/images/home/logos/atoss.svg" />
              <img src="../assets/images/home/logos/edelmann_group.svg" />
              <img src="../assets/images/home/logos/gemic.svg" />
              <img src="../assets/images/home/logos/sika.svg" />
              <img src="../assets/images/home/logos/vitra.svg" />
              <img src="../assets/images/home/logos/miradore.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="teasers teasers--blog">
      <div class="container grid-md">
        <div class="columns">
          <div class="column col-7 col-sm-12">
            <h2 v-html="$t('home.blog.headline')"></h2>
          </div>
        </div>
      </div>
      <div class="container grid-2x">
        <div class="columns">
          <div class="column col-4 col-lg-12 column--bottom">
            <a
              :href="$t('home.blog.href')"
              class="button button--arrow-left button--right"
              target="_blank"
              >{{ $t("home.blog.link") }}</a
            >
          </div>
          <PostFeed />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TeamGallery from "@/components/TeamGallery.vue";
import PostFeed from "@/components/PostFeed.vue";
// import LocaleChanger from "@/components/LocaleChanger.vue";
import { directive } from "vue-scroll-context";

export default {
  name: "Home",
  directives: {
    scrollContext: directive,
  },
  components: {
    // LocaleChanger,
    TeamGallery,
    PostFeed,
  },
  data() {
    return {
      activeTab: 1,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    scrollCallback(el, ctx) {
      if (window.innerWidth >= 840 && !!this.$refs.personalities) {
        if (ctx.rect.y < 900 && ctx.rect.y > 680) {
          this.$refs.personalities.style.opacity = 0;
        } else if (ctx.rect.y < 680 && ctx.rect.y > 300) {
          this.$refs.personalitiesText.style.transform = `translate3d(0, ${Math.round(
            ctx.rect.y / 3
          ) - 100}px, 0)`;
          if (ctx.rect.y < 600 && ctx.rect.y > 300) {
            this.$refs.personalities.style.opacity = 0;
          }
        } else if (ctx.rect.y < 300) {
          this.$refs.personalities.style.opacity = 1;
          this.$refs.personalitiesText.style.transform = "translate3d(0, 0, 0)";
        } else {
          this.$refs.personalities.style.opacity = 0;
        }
      }
    },

    scrollImage(el, ctx, params) {
      if (
        window.innerWidth >= 840 &&
        !!this.$refs.introImage1 &&
        !!this.$refs.introTextGroup
      ) {
        el.style.transform = `translate3d(-14.5vw, ${(ctx.rect.y / 100) *
          params.multiplier -
          2}vw, 0)`;
      }
    },
    handleResize() {
      if (this.$refs.length > 0) {
        this.$refs.teasersGroup.style = "";
        this.$refs.personalities.style = "";
        this.$refs.culture.style = "";
      }
    },
  },
};
</script>
<style scoped lang="scss">
h1 {
  @include fadein;
  @media (min-width: $size-md) {
    @include font-size(50);

    line-height: 3rem;
  }
}

.intro {
  @media (min-width: $size-sm) {
    min-height: 1000px;
  }

  @media (min-width: $size-sm) and (max-height: 1024px) {
    height: 100vh;
    position: relative;
  }

  @media (min-width: $size-md) {
    min-height: 800px;
  }

  background-image: linear-gradient(
    to bottom,
    $gradient-color-start,
    $gradient-color-end
  );
  overflow: hidden;

  &__image {
    @media (min-width: $size-sm) {
      transform: translate3d(0, 0, 0);
      width: 90vw;
    }

    @media (min-width: $size-md) {
      transform: translate3d(-7rem, 1rem, 0);
      width: 60vw;
    }

    @media (min-width: $size-xl) {
      width: 56vw;
    }

    width: 100%;
    max-width: 843px;
    object-fit: cover;
    shape-outside: url("../assets/images/home/nm_home_header_illustration1.png");

    &--mobile {
      @media (min-width: $size-md) {
        display: none;
      }
    }

    &[src*="illustration0"] {
      @media (max-width: $size-md) {
        display: none;
      }

      left: 0;
      transform: translate3d(-14.5vw, -2.91089rem, 0);
      position: absolute;
      top: 10vw;
      z-index: 0;
    }

    &[src*="illustration1"] {
      @include fadein(2.4s);

      @media (max-width: $size-md) {
        display: none;
      }

      left: 0;
      position: absolute;
      transform: translate3d(-14.5vw, -2.91089rem, 0);
      top: 10vw;
      z-index: 1;
    }
  }

  &__image-group {
    position: relative;
  }

  &__text-group {
    @include fadein(1.8s);
  }

  h1 {
    @media (max-width: $size-sm) {
      @include font-size(32);

      max-width: 10em;
      margin-bottom: 1.5rem;
      margin-top: 0.5rem;
      letter-spacing: 0.02rem;
    }

    @media (min-width: $size-sm) {
      @include font-size(50);
    }
  }

  h3 {
    @media (max-width: $size-sm) {
      @include font-size(24);

      margin-bottom: 2.5rem;
    }

    margin-bottom: 6vw;
    font-weight: 400;
    margin-top: 0;
  }

  .button--arrow-right {
    width: clamp(10em, 15.5em, 100%);
    display: block;
    max-width: none;
    z-index: 2;
    position: relative;
  }
}

@media (max-width: $size-md) {
  .intro {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

@media (min-width: $size-md + 1px) {
  .home {
    margin-top: -$header-wrapper;
  }

  .intro {
    padding-top: 12rem;
    padding-bottom: 2rem;
  }
}

.teasers {
  margin-bottom: 8rem;

  h3 {
    margin-bottom: 1.4rem;
    white-space: pre-line;
  }

  img {
    @media (max-width: $size-md) {
      max-width: 100%;
    }
  }

  &__group {
    position: relative;
    z-index: 0;
    transition: background-color 0.5s 0s ease-in-out;

    &-tab {
      padding-top: 14vw;
      position: sticky;
      top: 0;
      z-index: 3;
    }

    &--colored {
      background-color: $gradient-color-start;
    }
  }

  &--culture {
    @media (min-width: $size-md) {
      position: sticky;
      top: 2rem;
      z-index: 0;
    }

    transition: opacity 0.7s 0s ease-in-out;
    padding-top: 4rem;
    margin-bottom: 3rem;

    .teaser {
      @media (min-width: $size-md) {
        @include font-size(24);
      }

      @include font-size(22);

      ::v-deep p {
        line-height: 1.6;
        letter-spacing: 0.035rem;
      }
    }
  }

  &--personalities {
    @media (min-width: $size-md) {
      position: relative;
      background-size: auto, auto;
      z-index: 2;
    }

    background-image: url("../assets/images/home/nm_home_intro_illustration.png"),
      linear-gradient(
        to bottom,
        $gradient-color-start 0%,
        $gradient-color-end 90%,
        $color-white 90%
      );
    background-repeat: no-repeat, repeat-y;
    background-position: bottom right, center;
    background-size: 60vw auto, 100% auto;
    padding: 4rem 0 4rem;
    transition: opacity 0.7s 0s ease-in-out;
    box-shadow: inset 0px 1px 0px $gradient-color-start;

    .teaser {
      @media (min-width: $size-md) {
        @include font-size(24);
      }

      @include font-size(22);

      ::v-deep p {
        line-height: 1.6;
        letter-spacing: 0.035rem;
      }

      ::v-deep p:last-child {
        &::before {
          @media (min-width: $size-md) {
            height: 5em;
            width: 15em;
          }

          @media (min-width: $size-xl) {
            height: 0;
          }

          content: "";
          display: block;
          width: 15em;
          height: 6em;
          shape-outside: polygon(50% 0%, 0% 100%, 100% 100%);
          float: right;
          margin-right: -8em;
        }
      }
    }
  }

  &--team {
    position: relative;

    .team-gallery {
      margin-bottom: 2rem;
    }

    h2 {
      @media (max-width: $size-md) {
        font-size: 2rem;
      }
    }

    &:before {
      @media (min-width: $size-sm) {
        width: 58.33333333%;
      }

      @media (min-width: $size-md) and (max-width: $size-xl - 1) {
        height: 6.5rem;
      }

      background: $color-primary-50;
      content: "";
      display: block;
      height: 7.5rem;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }
  }

  &--work,
  &--candidate,
  &--futurehunt,
  &--network {
    .columns {
      align-items: center;
    }

    .column {
      order: 1;

      &.txt--right {
        @media (min-width: $size-sm) {
          order: 1;
          display: flex;
          align-items: flex-start;
        }

        order: 0;
      }
    }
  }

  &--blog {
    @extend .teasers--team;

    margin-bottom: 11rem;
    margin-top: 2rem;

    h2 {
      @media (min-width: $size-sm) {
        margin-bottom: 0;
      }

      margin-top: 2.8rem;
      margin-bottom: 2rem;
    }

    ::v-deep h4 {
      line-height: 1.4;
    }

    &::before {
      @media (min-width: $size-md) {
        height: 7.5rem;
      }

      height: 10.5rem;
    }

    .column--bottom {
      @media (max-width: $size-lg - 1) {
        order: 1;
      }

      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      padding-bottom: 1rem;

      .button {
        @media (min-width: $size-md) {
          margin-right: $layout-spacing;
        }
      }
    }
  }
}

.teaser {
  &__subline {
    @media (max-width: $size-md) {
      display: none;
    }

    display: inline-flex;
    letter-spacing: 1.5px;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.4;
    margin-bottom: 2em;
    transition: opacity 0.5s 0s ease-in-out;

    &-separator {
      margin: 0 0.2em;
    }

    span {
      transition: color 0.5s 0s ease-in-out;
    }

    &--inactive {
      color: transparentize($color-primary-700, 0.8);
    }
  }

  &__image {
    .teasers--personalities & {
      margin-top: -15em;
      transform: translateX(10em);
    }
  }

  @media (min-width: $size-md) {
    &--push-up {
      transform: translateY(-7rem);
    }

    &--push-down {
      margin-top: 5rem;
    }

    &--push-down-more {
      margin-top: 24vw;
    }
  }
}

.logo {
  &__container {
    @media (min-width: $size-sm) {
      margin: 0 -#{$container-padding};
    }

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 -8vw;

    img {
      @media (min-width: $size-md) {
        max-height: 110px;
        max-width: none;
      }

      flex: 0 1 calc(21% - 2rem);
      max-height: 80px;
      object-fit: contain;
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}
</style>
