<template>
  <div class="post-feed column col-8 col-md-offset-0 col-md-12 col-lg-10 col-lg-offset-2 col-xl-8">
    <div class="columns">
      <div class="column col-6 col-sm-12 col-md-6 col-lg-6" v-for="(post, index) in posts" :key="index" :class="listItemCssClass(index)">
        <a :href="post.link" target="_blank">
          <div class="box box--shadowed">
            <h4>{{post.title.rendered}}</h4>
            <div v-html="formattedExcerpt(post.excerpt.rendered)"></div>
            <p>{{ $t('home.blog.c2a') }}</p>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PostFeed',
  data () {
    return {
      dataURL: 'https://nordic-minds.com/blog/wp-json/wp/v2/posts',
      queryOptions: {
        per_page: 2,
        page: 1,
        _embed: true
      },
      posts: []
    };
  },
  methods: {
    getRecentMessages () {
      axios
        .get(this.dataURL, { params: this.queryOptions })
        .then(response => {
          this.posts = response.data;
        })
        .catch(error => { // eslint-disable-next-line
          console.log(error);
        })
    },
    listItemCssClass(index) {
      return index % 2 === 0 ? '' : 'col-offset-0 col-lg-offset-0';
    },
    formattedExcerpt(excerpt) {
      return excerpt.replace(/\[&#8230;\]/,'');
    }
  },
  mounted() {
    this.getRecentMessages();
  }
};
</script>

<style lang="scss" scoped>
.post-feed {
  @media(min-width: $size-sm) {
    margin-top: 1.5rem;
  }

  display: flex;
  flex-wrap: wrap;

  a {
    @media(max-width: $size-sm) {
      height: auto;
    }

    color: $color-primary-700;
    display: block;
    height: 100%;
  }

  .column {
    &:nth-child(2) {
      // display: none;

      @media(min-width: $size-md) {
        display: block;
      }
    }
  }

  .box {
    @media(min-width: $size-md) {
      padding: 3em 3em 2em;
    }

    background-color: $color-white;
    display: flex;
    flex-direction: column;
    height: 100%;
    letter-spacing: .05em;
    margin-bottom: $layout-spacing;
    padding: 1.5em 2em 2em;

    &--shadowed {
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
      transition: box-shadow .3s 0s ease;

      &:hover {
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);

        p {
          &::after {
            transform: translateX(.3em) rotateZ(45deg);
          }
        }
      }
    }

    h3 {
      margin-top: 0;
      color: $color-primary-700;
      flex: 0;
      letter-spacing: .03em;
    }

    div {
      flex: 1;
    }

    p {
      color: $color-primary;
      flex: 0;

      &::after {
        @media(min-width: $size-md) {
          height: 8px;
          width: 8px;
        }

        border: 0 solid $color-primary;
        border-right-width: 2px;
        border-top-width: 2px;
        content: '';
        display: inline-block;
        height: 7px;
        opacity: .8;
        transform: rotateZ(45deg);
        transform-origin: center;
        transition: transform .5s 0s ease-in-out;
        vertical-align: top;
        width: 7px;
        margin-top: 0.7em;
        margin-left: .3em;

      }
    }
  }
}
</style>
